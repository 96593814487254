import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { VirtuosoHandle } from 'react-virtuoso';
import { APIDefaultErrors } from '@api/baseAPI/types';
import { GetProductsErrorResponse, Product } from '@api/products/types';
import { Stack } from '@components/common';
import { DineInHeader } from '@components/dineIn';
import { OngoingOrderDialog } from '@components/order';
import {
  FavoriteProductList,
  PopularProductList,
  ProductCategoryDialog,
  ProductInputSearch,
  ProductList,
  ProductListSubmitButton,
  ProductSelectLocation,
} from '@components/products';
import routes from '@constants/routes';
import Token from '@constants/token';
import { usePersistentQueryParams } from '@hooks/common';
import { useDineInOrderDetail, useDineInRedirectByStatus } from '@hooks/dineIn';
import { useRedirectByLocationSchedule } from '@hooks/locations';
import { useCartStore, useCommonStore } from '@hooks/storage';
import { useQueryProductCartList, useQueryProducts } from '@queries/products';
import { useQuerySubBrands } from '@queries/subBrands';
import { getTotalProduct } from '@utils/storage/cartStore';

const ProductListContainer = () => {
  useDineInRedirectByStatus();
  const router = useRouter();
  const { query } = usePersistentQueryParams();
  const virtuosoRef = useRef<VirtuosoHandle>(null);
  const { storageState, isFinishInitiated: isFinishLoadCommonStore, updateStorage } = useCommonStore();
  const { data: subBrandsData } = useQuerySubBrands();

  const { isDineIn, dineInDetail, isFetching: isFetchingDineInDetail } = useDineInOrderDetail();

  const [activeCategoryList, setActiveCategoryList] = useState<string[]>();
  const [scrollDelay, setScrollDelay] = useState<Date>();
  const [isScrolling, setScrolling] = useState(true);

  const { data, isFetching, queryKeys } = useQueryProducts(
    {},
    {
      onError: (err: GetProductsErrorResponse | APIDefaultErrors) => {
        if ('errorType' in err && err.errorType === 'sub_brand_error' && isDineIn) {
          updateStorage({ subBrand: subBrandsData?.subBrands?.[0] });
        }
      },
    }
  );
  const { isFetching: isFetchingCartList, prices, productCartList } = useQueryProductCartList();

  const activeLocationId =
    isDineIn && dineInDetail?.locationId ? dineInDetail?.locationId : storageState.activeLocation?.id;

  const {
    branchCart,
    isFinishInitiated: isFinishLoadCartStore,
    updateProductCartItem,
  } = useCartStore(activeLocationId);

  const totalProduct = useMemo(() => getTotalProduct(branchCart), [branchCart]);
  const productCategories = useMemo(() => data?.productCategories || [], [data?.productCategories]);
  const popularProductIds = useMemo(() => data?.popularMenu?.productIds || [], [data?.popularMenu?.productIds]);
  const favoriteProductIds = useMemo(() => data?.favoriteMenu?.productIds || [], [data?.favoriteMenu?.productIds]);

  const queryClient = useQueryClient();

  const { isOpen, locationDetail } = useRedirectByLocationSchedule({
    locationId: isDineIn ? activeLocationId : storageState.activeLocation?.id,
    skipRedirect: true,
  });

  useEffect(() => {
    if (
      locationDetail?.id &&
      (!storageState.activeLocation?.id ||
        locationDetail?.id !== storageState.activeLocation?.id ||
        locationDetail?.guestModeDefault !== storageState.activeLocation?.guestModeDefault ||
        locationDetail?.requireGuestName !== storageState.activeLocation?.requireGuestName ||
        locationDetail?.requireGuestPhoneNumber !== storageState.activeLocation?.requireGuestPhoneNumber)
    ) {
      updateStorage({
        activeLocation: locationDetail,
      });
    }
  }, [
    locationDetail,
    storageState.activeLocation?.guestModeDefault,
    storageState.activeLocation?.requireGuestName,
    storageState.activeLocation?.requireGuestPhoneNumber,
    storageState.activeLocation?.id,
    updateStorage,
  ]);

  const categories = useMemo(
    () =>
      productCategories.map(({ productCategoryName, products }) => ({
        name: productCategoryName,
        total: products.length,
      })),
    [productCategories]
  );

  const productList = useMemo(() => {
    return productCategories.reduce((acc: Product[], productCategory) => {
      const currProducts = productCategory?.products || [];
      return [...acc, ...currProducts];
    }, []);
  }, [productCategories]);

  const onPressSubmit = () => {
    router.push({
      pathname: routes.PRODUCT_CART,
      query,
    });
  };

  const handleOnPress = useCallback((category: string) => {
    setActiveCategoryList([category]);
  }, []);

  useEffect(() => {
    if (activeCategoryList?.length === 0) {
      const firstTag = categories?.[0];
      if (firstTag) {
        handleOnPress(firstTag.name);
        return;
      }
    }
  }, [activeCategoryList?.length, categories, handleOnPress]);

  useEffect(() => {
    if (storageState.applicablePromoIds && storageState.applicablePromoIds?.length > 0) {
      updateStorage({ applicablePromoIds: [] });
    }
  }, [storageState.applicablePromoIds, updateStorage]);

  const popularProductList = useMemo(() => {
    return productList.filter(({ id }) => popularProductIds.includes(id));
  }, [popularProductIds, productList]);

  const favoriteProductList = useMemo(() => {
    return productList.filter(({ id }) => favoriteProductIds.includes(id));
  }, [favoriteProductIds, productList]);

  const isLoading =
    isFetching || !isFinishLoadCommonStore || !isFinishLoadCartStore || !storageState?.activeLocation?.id;

  const disabledSubmitButton = useMemo(() => {
    if (!isOpen && !isFetchingDineInDetail && !dineInDetail?.tableNo) {
      return true;
    }
    if (!isFetchingCartList) {
      const productIds = Object.keys(branchCart);

      return productIds.some((productId) => {
        const matchedProduct = productCartList.find(({ id }) => productId === String(id));

        if (!matchedProduct || matchedProduct?.outOfStockFlag) {
          queryClient.invalidateQueries(queryKeys);
          updateProductCartItem(Number(productId), {});
          return true;
        }

        const selectedProduct = branchCart[Number(productId)];
        return selectedProduct?.customItems?.some((customItem) =>
          customItem.productOptionSets?.some((optionSet) => {
            const matchedOptionSet = matchedProduct?.productOptionSets?.find?.(
              ({ optionSetId }) => optionSetId === optionSet?.optionSetId
            );
            return optionSet?.optionSetOptionIds?.some((optionSetOptionId) =>
              matchedOptionSet?.optionSetOptions?.some(
                ({ id, outOfStockFlag }) => optionSetOptionId === id && outOfStockFlag
              )
            );
          })
        );
      });
    }

    return true;
  }, [
    isOpen,
    isFetchingDineInDetail,
    dineInDetail?.tableNo,
    isFetchingCartList,
    branchCart,
    productCartList,
    queryClient,
    queryKeys,
    updateProductCartItem,
  ]);

  return (
    <Stack direction={'column'} paddingBottom={'m'} paddingLeft={'m'} paddingRight={'m'}>
      <Stack direction={'column'}>
        <Stack direction={'column'}>
          {!isFetchingDineInDetail && isDineIn && <DineInHeader />}
          {!isDineIn && <ProductSelectLocation />}
          <ProductInputSearch />
          {!!popularProductList.length && (
            <PopularProductList
              disabled={!isOpen}
              isLoading={isLoading}
              popularProductList={popularProductList}
              queryKeys={queryKeys}
            />
          )}
          {!!favoriteProductList.length && (
            <FavoriteProductList
              disabled={!isOpen}
              isLoading={isLoading}
              favoriteProductList={favoriteProductList}
              queryKeys={queryKeys}
            />
          )}

          <ProductList
            disabled={!isOpen}
            scrollDelay={scrollDelay}
            isLoading={isLoading}
            virtuosoRef={virtuosoRef}
            setActiveCategoryList={setActiveCategoryList}
            setScrolling={setScrolling}
            products={data}
            queryKeys={queryKeys}
          />
          <Stack height={10} />
        </Stack>
      </Stack>
      <Stack
        position={'sticky'}
        bottom={Token.spacing.hm}
        maxWidth={'max-content'}
        alignSelf={'center'}
        alignItems={'center'}
        zIndex={1}
        spacing={'hs'}
        visibility={!isScrolling ? 'visible' : 'hidden'}
      >
        <ProductCategoryDialog
          disabled={!isOpen}
          value={activeCategoryList}
          isLoading={isLoading}
          categories={categories}
          onPress={handleOnPress}
          setScrollDelay={setScrollDelay}
          virtuosoRef={virtuosoRef}
        />
        <ProductListSubmitButton
          disabled={disabledSubmitButton}
          onPress={onPressSubmit}
          totalProduct={totalProduct}
          totalPrice={String(prices.totalPrice)}
          isLoading={isFetchingCartList}
        />
      </Stack>
      <OngoingOrderDialog />
    </Stack>
  );
};

export default ProductListContainer;
